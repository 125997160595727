import React from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Webmain from './web';
import Navmain from './header';
import Footer from './footer';
import Aboutus from './aboutus';
import Privacy from './privacy';
import Websiteevelopment from './websitedevelopment';
import Appde from './appde';
import Websitem from './websitem';
import Digitalmrk from './digitalmrk';
import Graphicdesign from './graphicdesign';
import Contact from './contact';


function App() {
  return (
   <>
   <BrowserRouter>
        <Routes>
        <Route path='/' index element={<Webmain />} />
        <Route path='/header' index element={<Navmain />} />
        <Route path='/footer' index element={<Footer/>} />
        <Route path='/aboutus' index element={<Aboutus/>}/>
        <Route path='/websitedevelopment' index element={<Websiteevelopment/>}/>
        <Route path='/appde' index element={<Appde/>}/>
        <Route path='/websitem' index element={<Websitem/>}/>
        <Route path='/digitalmrk' index element={<Digitalmrk/>}/>
        <Route path='/graphicdesign' index element={<Graphicdesign/>}/>
        <Route path='/contact' index element={<Contact/>}/>
        <Route path='/privacy' index element={<Privacy/>}/>
        </Routes>
        </BrowserRouter>
   </>
  );
}

export default App;
