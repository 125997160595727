import React from 'react'

import {MDBContainer, MDBCarousel, MDBCarouselItem, MDBCarouselCaption,  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol } from 'mdb-react-ui-kit';
import Navmain from './header';
import Footer from './footer';
import Webdevelopment from './img/websitedevelopment.jpg';
import App from './img/app.png';
import Maint from './img/websitemaintenance.png';
import Digital from './img/digitalmarketing.png';
import Design from './img/design.png';
import About from './img/aboutus.png';
import Banner1 from './img/banner1.png';
import Banner2 from './img/banner3.png';
import Banner3 from './img/banner4.png';

function web() {
  return (
    
    <>
    <Navmain />

    
    <div className='mt-2' >
    <MDBCarousel showIndicators showControls fade>
      <MDBCarouselItem itemId={1}>
        <img src={Banner1} className='d-block w-100' alt='...' />
        <MDBCarouselCaption>
          {/* <div style={{background: '#000', padding:'20px',     opacity:'0.2' }}></div> */}
            <div>
          <h5>Welcome to AIJN Infotech</h5>
          <p>In pursuit of providing value-added high quality IT solutions to our clients in selected horizontal and vertical segments, by combining technology skills, domain expertise, process focus and a commitment to long-term client relationships, AIJN INFOTECH was founded by highly energetic, experienced time-tested professionals.</p>
          </div>
          
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={2}>
        <img src={Banner2} className='d-block w-100' alt='...' />
        <MDBCarouselCaption>
        <div>
          <h5>Welcome to AIJN Infotech</h5>
          <p>In pursuit of providing value-added high quality IT solutions to our clients in selected horizontal and vertical segments, by combining technology skills, domain expertise, process focus and a commitment to long-term client relationships, AIJN INFOTECH was founded by highly energetic, experienced time-tested professionals.</p>
          </div>
        </MDBCarouselCaption>
      </MDBCarouselItem>

      <MDBCarouselItem itemId={3}>
        <img src={Banner3} className='d-block w-100' alt='...' />
        <MDBCarouselCaption>
        <div>
          <h5>Welcome to AIJN Infotech</h5>
          <p>In pursuit of providing value-added high quality IT solutions to our clients in selected horizontal and vertical segments, by combining technology skills, domain expertise, process focus and a commitment to long-term client relationships, AIJN INFOTECH was founded by highly energetic, experienced time-tested professionals.</p>
          </div>
        </MDBCarouselCaption>
      </MDBCarouselItem>
    </MDBCarousel>

    </div>


    

<div className='mt-5'>

<MDBContainer>
<MDBRow>
<MDBCol md="6" lg="6" xl="6">
<div className='clearfix' >
      {/* <svg style={{margin:'0px 20px 0 0'}}
          className='bd-placeholder-img col-md-6 mb-3 ms-md-3'
          width='100%'
          height='210'
          xmlns='http://www.w3.org/2000/svg'
          role='img'
          aria-label='Placeholder: Responsive floated image'
          preserveAspectRatio='xMidYMid slice'
          focusable={false}
        >
          <title>Placeholder</title>
          <rect width='100%' height='100%' fill='#868e96'></rect>
          <text x='50%' y='50%' fill='#dee2e6' dy='.3em'>
            Responsive floated image
          </text>
        </svg> */}

<MDBCardImage
            src={About}
            alt='...'
            position='top'
          style={{width:'100%', margin:'0 20px 0px 0px'}}/>
       
      </div>
      </MDBCol>
      <MDBCol md="6" lg="6" xl="6">
       <div style={{margin:'0px 0 0 0'}}>
          <h3>About AIJN Infotech</h3>

          <p>
            
<strong>Mission :</strong> To partner with our clients and to improve their competitive advantage in the most productive way using the latest tools, methods, and accelerators.<br></br>
<strong style={{margin:'10px 0 0 0', display:'inline-block'}}>Vision :</strong>  Our goal is to add significant value to Client's existing investment in Information Technology.
          </p>
        <p>
        In pursuit of providing value-added high quality IT solutions to our clients in selected horizontal and vertical segments, by combining technology skills, domain expertise, process focus and a commitment to long-term client relationships, AIJN Infotech was founded by highly energetic, experienced time-tested professionals.
        </p>
        <p>
        In pursuit of providing value-added high quality IT solutions to our clients in selected horizontal and vertical segments, by combining technology skills, domain expertise, process focus and a commitment to long-term client relationships, AIJN Infotech was founded by highly energetic, experienced time-tested professionals.
        </p>
        <p>
        <button type="button" class="btn btn-info" data-mdb-ripple-init>Read More</button>
        </p>

        </div>
      </MDBCol>
      </MDBRow>

    </MDBContainer>





<MDBContainer>


  <div style={{margin:'50px 0 0 0'}}>
    {/* <h1 className=''>Services</h1>text-center */}
    <p className='text-center mb-6 mt-6 text-decoration-underline'>
      <h1>Services</h1>
    </p>
  </div>
<MDBRow className='row-cols-1 row-cols-md-3 g-4'>
      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={Webdevelopment}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Website Development</MDBCardTitle>
            <MDBCardText>
            Developing a website involves creating engaging, informative, and user-friendly material that aligns with your brand and resonates with your target audience. Here are some key considerations for website content development
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={App}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>App Development</MDBCardTitle>
            <MDBCardText>
            App development website involves showcasing your expertise, services, and the value you bring to potential clients.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={Maint}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Website Maintenance</MDBCardTitle>
            <MDBCardText>
            Website development involves various aspects, from planning and design to coding and maintenance. Here's an overview of key topics related to website development.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={Digital}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Digital Marketing</MDBCardTitle>
            <MDBCardText>
            A Digital marketing website involves conveying your expertise in online promotion, lead generation, and brand visibility
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={Design}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Graphic Design</MDBCardTitle>
            <MDBCardText>
            A Graphic design website involves showcasing your creativity, skills, and the visual appeal of your work.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

    </MDBRow>
    </MDBContainer>
</div>

    <Footer/>
    </>
  )
}

export default web
