import React from 'react'

import {MDBContainer, MDBCarousel, MDBCarouselItem, MDBCarouselCaption,  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol } from 'mdb-react-ui-kit';
import Navmain from './header';
import Footer from './footer';
import About from './img/aboutus.png';
import Aboutus from './aboutus';
import Aboutmain from './img/aboutmain.png';


function aboutus() {
  return (
    <>
    <Navmain />

    <div>
    <MDBContainer>
    <MDBCardImage
            src={Aboutmain}
            alt='...'
            position='top'
            style={{margin:'10px 0 0 0'}}
          />
          </MDBContainer>
    </div>
    <div className='mt-6'>
  
      <div className='clearfix' style={{display:'flex', margin:'80px 0 80px 0'}}>
      <MDBContainer>
<MDBRow>
<MDBCol md="6" lg="6" xl="6">
<div className='clearfix' >
      {/* <svg style={{margin:'0px 20px 0 0'}}
          className='bd-placeholder-img col-md-6 mb-3 ms-md-3'
          width='100%'
          height='210'
          xmlns='http://www.w3.org/2000/svg'
          role='img'
          aria-label='Placeholder: Responsive floated image'
          preserveAspectRatio='xMidYMid slice'
          focusable={false}
        >
          <title>Placeholder</title>
          <rect width='100%' height='100%' fill='#868e96'></rect>
          <text x='50%' y='50%' fill='#dee2e6' dy='.3em'>
            Responsive floated image
          </text>
        </svg> */}

<MDBCardImage
            src={About}
            alt='...'
            position='top'
          style={{width:'100%', margin:'0 20px 0px 0px'}}/>
       
      </div>
      </MDBCol>
      <MDBCol md="6" lg="6" xl="6">
       <div >
          <h3>About AIJN Infotech</h3>

          <p>
            
<strong>Mission :</strong> To partner with our clients and to improve their competitive advantage in the most productive way using the latest tools, methods, and accelerators.<br></br>
<strong style={{margin:'10px 0 0 0', display:'inline-block'}}>Vision :</strong>  Our goal is to add significant value to Client's existing investment in Information Technology.
          </p>
        <p>
        In pursuit of providing value-added high quality IT solutions to our clients in selected horizontal and vertical segments, by combining technology skills, domain expertise, process focus and a commitment to long-term client relationships, AIJN Infotech was founded by highly energetic, experienced time-tested professionals.
        </p>
        <p>
        In pursuit of providing value-added high quality IT solutions to our clients in selected horizontal and vertical segments, by combining technology skills, domain expertise, process focus and a commitment to long-term client relationships, AIJN Infotech was founded by highly energetic, experienced time-tested professionals.
        </p>
        <p>
        <button type="button" class="btn btn-info" data-mdb-ripple-init>Read More</button>
        </p>

        </div>
      </MDBCol>
      </MDBRow>

    </MDBContainer>


        
      </div>
 
    </div>
    <Footer/>
    </>
  )
}

export default aboutus;