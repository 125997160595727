import React, {useState} from 'react'

import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse,
  } from 'mdb-react-ui-kit';
  import Logo from './img/Logo.svg';
  import { Link } from 'react-router-dom';

function header() {

    const [openBasic, setOpenBasic] = useState(false);


  return (
    <MDBNavbar expand='lg' light bgColor='light' className='stycky'>
      <MDBContainer>
      <MDBNavbarBrand href='#'>
            <img
              src={Logo}
              height='50'
              alt=''
              loading='lazy'
            />
          </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setOpenBasic(!openBasic)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar open={openBasic}>
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
            <MDBNavbarItem>
           
             <Link to='/'>
              <MDBNavbarLink active aria-current='page' href='#'>
                Home
              </MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
            <Link to="/aboutus">

              <MDBNavbarLink href='#'>About Us</MDBNavbarLink>
             </Link>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                Services
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <Link to="/websitedevelopment">
                  <MDBDropdownItem link>Website Development</MDBDropdownItem>
                  </Link>
                  <Link to='/appde'>
                  <MDBDropdownItem link>App Development</MDBDropdownItem>
                  </Link>
                  <Link to="/websitem">
                  <MDBDropdownItem link>Website Maintenance</MDBDropdownItem>
                  </Link>
                  <Link to="/digitalmrk">
                  <MDBDropdownItem link>Digital Marketing</MDBDropdownItem>
                  </Link>
                  <Link to="/graphicdesign">
                  <MDBDropdownItem link>Graphic Design</MDBDropdownItem>
                  </Link>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <Link to="/contact">
              <MDBNavbarLink href='#'>Contact</MDBNavbarLink>
              </Link>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to="/privacy">
              <MDBNavbarLink href='#'>Privacy</MDBNavbarLink>
              </Link>
            </MDBNavbarItem>

            

            {/* <MDBNavbarItem>
              <MDBNavbarLink disabled href='#' tabIndex={-1} aria-disabled='true'>
                Disabled
              </MDBNavbarLink>
            </MDBNavbarItem> */}
          </MDBNavbarNav>

        
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  )
}
export default header;

